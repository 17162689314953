a.templateName {
    color: var(--black);
    text-transform: none;
    text-decoration: none;
}

a.templateName:hover {
    color: var(--black);
}

.status {
    color: var(--black);
}

.status[data-status='Active'] {
    color: var(--success);
}

.status[data-status='Inactive'] {
    color: var(--inactive);
}
