.container {
    margin-top: 0.5rem;
    margin-right: 14%;
    margin-left: 14%;
}

.container:before,
.container:after {
    display: table;
    content: ' ';
}

.container:after {
    clear: both;
}

.container :global(.ant-layout-footer) {
    padding: 0;
}
