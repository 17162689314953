/*
With the combination of Nextjs and Css modules it's not possible to use a more specific Css selector with child relations,
So !important becomes necessary to override global antd styles
Using antd ConfigProvider component design tokens with ColorBgElevated allows us to set the message container background color
But it sets it globally so we cannot differentiate between success and error messages
Content of the container is entirely customizable so we are hiding the container and showing our content instead
*/
.messageContainerGlobal :global(.ant-message-notice-content) {
    visibility: hidden;
    padding: 0 !important;
}

.errorMessageContainerCustom,
.successMessageContainerCustom {
    border-radius: var(--border-radius-round);
    visibility: visible;
    width: 100%;
    height: 100%;
    padding: 9px 12px;
}

.successMessageContainerCustom {
    background: var(--success-message-background);
}

.errorMessageContainerCustom {
    background: var(--error-message-background);
}

.messageIcon {
    color: var(--white);
}

.messageText {
    color: var(--white);
    width: 100%;
}
